//react
import { FC } from 'react'
import { Routes, Route } from 'react-router-dom'

//general
import NotFound from './pages/general/notFound/notFound'
import NotAuthorised from './pages/general/notAuthorised/notAuthorised'
import Profile from './pages/general/profile/profile'
import Home from './pages/general/home/home'
import Notifications from './pages/general/notifications/notifications'
import Settings from './pages/general/settings/settings'
import Order from './pages/general/order/order'
import Employee from './pages/general/employee/employee'
import TimeTrackers from './pages/general/timeTrackers/timeTrackers'
import Updating from './pages/general/updating/updating'
import ServerError from './pages/errors/serverErrors/500/serverError'

//authentication
import Login from './pages/authentication/login/login'
import ResetPasswordEmail from './pages/authentication/resetPasswordEmail/resetPasswordEmail'
import ResetPassword from './pages/authentication/resetPassword/resetPassword'
import Confirmation from './pages/authentication/confirmation/confirmation'
import Register from './pages/authentication/register/register'
import TermsOfUse from './pages/authentication/termsOfUse/termsOfUse'
import PrivacyPolicy from './pages/authentication/privacyPolicy/privacyPolicy'

//employees
import Hierachy from './pages/employees/hierarchy/hierarchy'
import SearchEmployees from './pages/employees/searchEmployee/searchEmployees'
import Chat from './pages/general/chat/chat'
import EmployeesStats from './pages/employees/employeesStats/employeesStats'
import Productivity from './pages/employees/productivity/productivity'

//projects
import PipelineList from './pages/projects/pipelineList/pipelineList'
import Pipeline from './components/projects/pipelines/pipeline'
import Project from './pages/projects/project/project'
import MyTasks from './pages/projects/myTasks/myTasks'
import ProjectsStats from './pages/projects/projectsStats/projectsStats'
import Task from './pages/projects/task/task'

//purchase
import ProviderList from './pages/purchase/providerList/providerList'
import Provider from './pages/purchase/provider/provider'
import PurchseOrderList from './pages/purchase/purchaseOrderList/purchaseOrderList'
import PurchaseStats from './pages/purchase/purchaseStats/purchaseStats'
import ProviderOffering from './pages/purchase/providerOffering/providerOffering'

//sales
import CustomerList from './pages/sales/customerList/customerList'
import Customer from './pages/sales/customer/customer'
import SalesOrderList from './pages/sales/salesOrderList/salesOrderList'
import OfferingList from './pages/sales/offeringList/offeringList'
import Offering from './pages/sales/offering/offering'
import SalesStats from './pages/sales/salesStats/salesStats'

//warehouse
import WarehouseList from './pages/warehouse/warehouseList/warehouseList'
import Warehouse from './pages/warehouse/warehouse/warehouse'
import MovingList from './pages/warehouse/movingList/movingList'
import ShipmentList from './pages/warehouse/shipmentList/shipmentList'
import InventoryList from './pages/warehouse/inventoryList/inventoryList'
import WarehouseStats from './pages/warehouse/warehouseStats/warehouseStats'
import InventoryItem from './pages/warehouse/inventoryItem/inventoryItem'

//landing
import LandingHome from './apps/landing/pages/home/home'
import AppInformation from './apps/landing/pages/appInformation/appInformation'
import Prices from './apps/landing/pages/prices/prices'
import StartOfWork from './apps/landing/pages/startOfWork/startOfWork'
import Help from './apps/landing/pages/help/help'
import SetUpManual from './apps/landing/pages/manual/general/setUpManual'
import NotificationManual from './apps/landing/pages/manual/general/notificationManual'
import SettingsManual from './apps/landing/pages/manual/general/settingsManual'
import SingleOrderManual from './apps/landing/pages/manual/general/singleOrderManual'

//finance
import AccountList from './pages/finance/accountList/accountList'
import Account from './pages/finance/account/account'
import TransactionList from './pages/finance/transactionList/transactionList'
import BillList from './pages/finance/billList/billList'
import Bill from './pages/finance/bill/bill'
import Budget from './pages/finance/budget/budget'
import BudgetList from './pages/finance/budgetList/budgetList'
import FinanceStats from './pages/finance/financeStats/financeStats'
import FinancialReports from './pages/finance/financialReports/financialReports'

//manuals

//employees
import GeneralEmployeeManual from './apps/landing/pages/manual/employees/generalEmployeeManual'
import HierarchyEmployeeManual from './apps/landing/pages/manual/employees/hierarchyEmployeeManual'
import SearchEmployeeManual from './apps/landing/pages/manual/employees/searchEmployeeManual'

//projects
import GeneralProjectManual from './apps/landing/pages/manual/projects/generalProjectManual'
import SinglePipelineManual from './apps/landing/pages/manual/projects/singlePipelineManual'
import SingleProjectManual from './apps/landing/pages/manual/projects/singleProjectManual'
import MyTaskManual from './apps/landing/pages/manual/projects/myTaskManual'
import SingleTaskManual from './apps/landing/pages/manual/projects/singleTaskManual'

//purchases
import GeneralPurchaseManual from './apps/landing/pages/manual/purchase/generalPurchaseManual'
import ProviderManual from './apps/landing/pages/manual/purchase/providersManual'
import SingleProviderManual from './apps/landing/pages/manual/purchase/singleProviderManual'

//sales
import GeneralSaleManual from './apps/landing/pages/manual/sales/generalSaleManual'
import CustomerManual from './apps/landing/pages/manual/sales/customerManual'
import OfferingManual from './apps/landing/pages/manual/sales/offeringManual'
import SingleCustomerManual from './apps/landing/pages/manual/sales/singleCustomerManual'
import SingleOfferingManual from './apps/landing/pages/manual/sales/singleOfferingManual'

//finance
import GeneralFinanceManual from './apps/landing/pages/manual/finance/generalFinanceManual'
import AccountManual from './apps/landing/pages/manual/finance/accountManual'
import TransactionManual from './apps/landing/pages/manual/finance/transactionManual'
import BillManual from './apps/landing/pages/manual/finance/billManual'
import StatisticsManual from './apps/landing/pages/manual/finance/statisticsManual'
import BudgetManual from './apps/landing/pages/manual/finance/budgetManual'

//warehouses
import GeneralWarehouseManual from './apps/landing/pages/manual/warehouse/generalWarehouseManual'
import SingleInventoryItemManual from './apps/landing/pages/manual/warehouse/singleInventoryItemManual'
import InventoryManual from './apps/landing/pages/manual/warehouse/inventoryManual'
import SingleWarehouseManual from './apps/landing/pages/manual/warehouse/singleWarehouseManual'
import WarehouseManual from './apps/landing/pages/manual/warehouse/warehouseManual'


const Router: FC = () => {
	return (
    <Routes>
      <Route path="/">
        <Route path="" element={<LandingHome />}/>
        <Route path="help/support/:section?" element={<Help />} />
        <Route path="prices/:page/:section?" element={<Prices />} />
        <Route path="help/start-of-work/:section?" element={<StartOfWork />} />
        <Route path="app-information/:page/:section?" element={<AppInformation />} />
        <Route path="solutions/:page/:section?" element={<AppInformation />} />
        <Route path="login" element={<Login />} />
        <Route path="reset-password-email" element={<ResetPasswordEmail />} />
        <Route path="reset-password/:token" element={<ResetPassword />} />
        <Route path="register" element={<Register />} />
        <Route path="confirmation/:email" element={<Confirmation />} />
        <Route path="accept-invitation">
          <Route path="register/:token" element={<Register />} />
          <Route path="login/:token" element={<Login />} />
          <Route path="confirmation/:email/:token" element={<Confirmation />} />
        </Route>
        <Route path="terms-of-use" element={<TermsOfUse />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="updating/:text" element={<Updating />} />
        <Route path="help/ri-manual">
          <Route path="employees">
            <Route index element={<GeneralEmployeeManual />} />
            <Route path="hierarchy" element={<HierarchyEmployeeManual />} />
            <Route path="search-employee" element={<SearchEmployeeManual />} />
          </Route>

          <Route path="projects">
            <Route index element={<GeneralProjectManual />} />
            <Route path="single-pipeline" element={<SinglePipelineManual />} />
            <Route path="single-project" element={<SingleProjectManual />} />
            <Route path="my-tasks" element={<MyTaskManual />} />
            <Route path="single-task" element={<SingleTaskManual />} />
          </Route>

          <Route path="purchases">
            <Route index element={<GeneralPurchaseManual />} />
            <Route path="providers" element={<ProviderManual />} />
            <Route path="single-provider" element={<SingleProviderManual />} />
          </Route>

          <Route path="sales">
            <Route index element={<GeneralSaleManual />} />
            <Route path="customers" element={<CustomerManual />} />
            <Route path="offerings" element={<OfferingManual />} />
            <Route path="single-customer" element={<SingleCustomerManual />} />
            <Route path="single-offering" element={<SingleOfferingManual />} />
          </Route>

          <Route path="finance">
            <Route index element={<GeneralFinanceManual />} />
            <Route path="accounts" element={<AccountManual />} />
            <Route path="transactions" element={<TransactionManual />} />
            <Route path="bills" element={<BillManual />} />
            <Route path="budgets" element={<BudgetManual />} />
            <Route path="statistics" element={<StatisticsManual />} />
          </Route>

          <Route path="warehouses">
            <Route index element={<GeneralWarehouseManual />} />
            <Route
              path="single-inventory-item"
              element={<SingleInventoryItemManual />}
            />
            <Route path="inventory" element={<InventoryManual />} />
            <Route
              path="single-warehouse"
              element={<SingleWarehouseManual />}
            />
            <Route path="warehouses" element={<WarehouseManual />} />
          </Route>

          <Route path="general">
            <Route
              path="single-order"
              element={
                  <SingleOrderManual />
              }
            />
            <Route
              path="set-up"
              element={
                  <SetUpManual />
              }
            />
            <Route
              path="notifications"
              element={
                  <NotificationManual />
              }
            />
            <Route
              path="settings"
              element={
                  <SettingsManual />
              }
            />
          </Route>
        </Route>
        <Route path="error/500" element={<ServerError />} />
      </Route>
      <Route path="/ri-business">
        <Route path="time-trackers" element={<TimeTrackers />} />
        <Route path="employee/:employeeId" element={<Employee />} />
        <Route path="profile/*" element={<Profile />} />
        <Route path="settings" element={<Settings />} />
        <Route path="notifications" element={<Notifications />} />
        <Route path="chats" element={<Chat />}>
          <Route path=":chatId" element={<Chat />} />
        </Route>
        <Route path="order/:orderId" element={<Order />} />
        <Route path="" element={<Home />} />
        <Route path="*" element={<NotFound />} />
        <Route path="403" element={<NotAuthorised />} />
        <Route path="employees">
          <Route path="hierarchy" element={<Hierachy />} />
          <Route path="search-employee" element={<SearchEmployees />} />
          <Route path="statistics" element={<EmployeesStats />} />
          <Route path="productivity" element={<Productivity />} />
        </Route>
        <Route path="projects">
          <Route path="pipelines" element={<PipelineList />} />
          <Route path="pipeline/:pipelineId" element={<Pipeline />} />
          <Route path="project/:projectId" element={<Project />} />
          <Route path="my-tasks" element={<MyTasks />} />
          <Route path="statistics" element={<ProjectsStats />} />
          <Route path="task/:taskId" element={<Task />} />
        </Route>
        <Route path="warehouse">
          <Route path="warehouse-list" element={<WarehouseList />} />
          <Route path="inventory" element={<InventoryList />} />
          <Route
            path="inventory-item/:inventoryItemId"
            element={<InventoryItem />}
          />
          <Route path="shipments" element={<ShipmentList />} />
          <Route path="inventory/item/:itemId" element={<WarehouseList />} />
          <Route path="moving-list" element={<MovingList />} />
          <Route path="statistics" element={<WarehouseStats />} />
          <Route path="warehouse/:warehouseId" element={<Warehouse />} />
          <Route path="inventory/item/:itemId" element={<WarehouseList />} />
        </Route>
        <Route path="sales">
          <Route path="order-list" element={<SalesOrderList />} />
          <Route path="customer-list" element={<CustomerList />} />
          <Route path="customer/:relationshipId" element={<Customer />} />
          <Route path="offering-list" element={<OfferingList />} />
          <Route path="offering/:offeringId" element={<Offering />} />
          <Route path="statistics" element={<SalesStats />} />
        </Route>
        <Route path="purchase">
          <Route path="provider-list" element={<ProviderList />} />
          <Route
            path="provider/offering/:offeringId"
            element={<ProviderOffering />}
          />
          <Route path="provider/:relationshipId" element={<Provider />} />
          <Route path="order-list" element={<PurchseOrderList />} />
          <Route path="statistics" element={<PurchaseStats />} />
        </Route>
        <Route path="finance">
          <Route path="account-list" element={<AccountList />} />
          <Route path="account/:accountId" element={<Account />} />
          <Route path="transaction-list" element={<TransactionList />} />
          <Route path="bill-list" element={<BillList />} />
          <Route path="bill/:billId" element={<Bill />} />
          <Route path="budget-list" element={<BudgetList />} />
          <Route path="budget/:budgetId" element={<Budget />} />
          <Route path="statistics" element={<FinanceStats />} />
          <Route path="financial-reports" element={<FinancialReports />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default Router
