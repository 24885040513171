//style
import './landingTextSection.scss'

//react
import { useNavigate } from 'react-router-dom'
import Title from '../title/title'

// components
import ScrollAnimation from '../scrollAnimation/scrollAnimation'
import Button from '../../../../../components/general/button/button'

//translations
import { useTranslation } from 'react-i18next'
import Text from '../text/text'
import { FC } from 'react'
import { noCreditCardRequiredIcon } from '../../../assets/icons/icons'

type landingTextSectionProps = {
	title: string
	text: string
	translation: string
	icons?: {
		icon: React.ReactNode
		iconPosition?: {left: string, top: string}
	}[] | null
	button?: {status: boolean, icon?: React.ReactNode}
}

const LandingTextSection: FC<landingTextSectionProps>  = ({title, text, icons, translation, button}) => {
	const { t } = useTranslation('', { keyPrefix: translation })
	const homeHeroT = useTranslation('', { keyPrefix: 'apps.landing.home.heroSection' }).t

	const navigate = useNavigate()

	return (
		<div className="landing-text-section-component-wrapper">
			{
				icons ? 
				icons.map((icon) => {
					return (
						<div 
							className="landing-text-section-container-icon"
							style={{
								left: icon.iconPosition?.left, 
								top: icon.iconPosition?.top,
							}}
						>
							{icon.icon}
						</div>
					);
				})
				: null
			}
			<div className="landing-text-section-component">
				<ScrollAnimation direction='to-bottom'>
					<div className="landing-text-section-content">
						<Title>
							{t(title)} <span className='title-black'>{t(`${title}Black`)}</span>
						</Title>
						{text ? <Text>{t(text)}</Text> : null}
						<div className='landing-text-section-content-actions'>
							<Button 
								active={true} 
								text={t('button1')} 
								onClick={() => navigate('/register')} 
								arrow={true} 
							/>
							{
								button ?
									<Button 
										active={true} 
										outlined={true} 
										icon={button.icon} 
										text={t('button2')} 
										onClick={() => {
											window.open(homeHeroT('demoLink'), "_blank")
										}} 
									/>
									: null
							}
						</div>
						<Text>{noCreditCardRequiredIcon}{t('mainCreditCard')}</Text>
					</div>
				</ScrollAnimation>
			</div>
		</div>
	)
}

export default LandingTextSection