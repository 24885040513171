//style
import './specialOffer.scss'
import { logo, noCreditCardRequiredIcon } from '../../../assets/icons/icons'

//react
import { useNavigate } from 'react-router-dom'
import { FC } from 'react'

// components
import ScrollAnimation from '../scrollAnimation/scrollAnimation'
import Button from '../../../../../components/general/button/button'
import Title from '../title/title'
import Text from '../text/text'

//translations
import { useTranslation } from 'react-i18next'

type specialOfferProps = {
	title: string
	text: string
	translation: string
	button?: {status: boolean, icon?: React.ReactNode}
}

const SpecialOffer: FC<specialOfferProps>  = ({title, text, translation, button}) => {
	const { t } = useTranslation('', { keyPrefix: translation })
	const heroSectionT = useTranslation('', { keyPrefix: 'apps.landing.home.heroSection' }).t

	const navigate = useNavigate()

	return (
		<div className="landing-special-offer-component-wrapper">
			<div className="landing-special-offer-component">
				<ScrollAnimation direction='to-bottom'>
					<div className="landing-special-offer-content">
						<div className="landing-special-offer-content-container">
							<Title>
								{t(title)} <span className='title-black'>{t(`${title}Black`)}</span>
							</Title>
							{text ? <Text>{t(text)}</Text> : null}
							<div className='landing-special-offer-content-actions'>
								<Button 
									active={false} 
									inverted={true} 
									outlined={true}
									text={t('button1')} 
									onClick={() => navigate('/register')} 
									arrow={true} 
								/>
								{
									button ?
										<Button 
											active={true} 
											inverted={true}
											icon={button.icon} 
											text={t('button2')} 
											onClick={() => {
												window.open(heroSectionT('demoLink'), "_blank")
											}} 
										/>
										: null
								}
							</div>
							<Text>{noCreditCardRequiredIcon}{t('mainCreditCard')}</Text>
						</div>
						<div className="logo-container">
							{logo}
						</div>
					</div>
				</ScrollAnimation>
			</div>
		</div>
	)
}

export default SpecialOffer