// styles
import './home.scss'

// components
import Layout from '../../components/general/layout/layout'
import HomeHeroSection from '../../components/home/homeHeroSection/homeHeroSection'
import HomeContent from '../../components/home/homeContent/homeContent'
import ParticlesBg from '../../components/general/particlesBg/particlesBg'

const Home = () => {
	return (
		<Layout>
			<HomeHeroSection/>
			<HomeContent/>
			<ParticlesBg/>
		</Layout>
	)
}

export default Home