// components
import Layout from '../../components/general/layout/layout'
import ParticlesBg from '../../components/general/particlesBg/particlesBg'
import TriplInfoContainer from '../../components/general/triplInfoContainer/triplInfoContainer'
import LandingSlider from '../../components/general/landingSlider/landingSlider'
import SpecialOffer from '../../components/general/specialOffer/specialOffer'

//icon
import { bulb } from '../../../../assets/general/generalIcons'
import { youtubeIcon } from '../../assets/icons/icons'

//react
import { lazy, useEffect } from 'react' 


const StartOfWork = () => {

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' })
	}, [])
	
	return (
		<Layout>
			<TriplInfoContainer
				translation='apps.landing.other.startOfWork'
				text='headerText'
				title='hint'
				headerText='headerDescription'
				headerTitle='headerTitle'
				icon={bulb}
			/>
			<LandingSlider
				translationWay='apps.landing.other.startOfWork.steps'
				stepsNumber={6}
				images={[
					require('../../assets/images/startOfWork/step1.png'),
					require('../../assets/images/startOfWork/step2.png'),
					require('../../assets/images/startOfWork/step3.png'),
					require('../../assets/images/startOfWork/step4.png'),
					require('../../assets/images/startOfWork/step5.png'),
					require('../../assets/images/startOfWork/step6.png')
				]}
				webpImages={[
					require('../../assets/images/startOfWork/step1.webp'),
					require('../../assets/images/startOfWork/step2.webp'),
					require('../../assets/images/startOfWork/step3.webp'),
					require('../../assets/images/startOfWork/step4.webp'),
					require('../../assets/images/startOfWork/step5.webp'),
					require('../../assets/images/startOfWork/step6.webp')
				]}
				title='stepTitle'
				text='stepText'
			/>
			<SpecialOffer
				translation='apps.landing.appInformation'
				text='specialOfferText'
				title='specialOfferTitle'
				button={{status: true, icon: youtubeIcon}}
			/>
			<ParticlesBg/>
		</Layout>
	)
}

export default StartOfWork