//styles
import './navbarLink.scss'
import { arrowIcon } from '../../../../../../../assets/general/generalIcons'

//components
import TextUnderline from '../../../textUnderline/textUnderline'

//react
import { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'



type navbarLinkProps = {
    link: string
    text: string
    dropdownElements?: {
        title: string
		icon: JSX.Element
		link?: string
        links: {
            text: string
            href: string
			icon?: JSX.Element
			description: string
			title: string
        }[]
    }[]
	open: boolean
	setOpen: (value: boolean) => void
}

const NavbarLink: FC<navbarLinkProps> = ({ link, text, dropdownElements, open, setOpen }) => {
	const [ openedSection, setOpenedSection ] = useState(dropdownElements ? dropdownElements[0] : null)

	const { t } = useTranslation('', { keyPrefix: 'apps.landing.general.layout.navbar' })

	const closeDropdowns = () => {
		setOpen(false)
	}

	useEffect(() => {
		window.addEventListener('click', closeDropdowns)

		return () => {
			window.addEventListener('click', closeDropdowns)
		}
	}, [])

	return (
		<div className="navbar-link-wrapper">
			<div className="navbar-link-container" onClick={(e) => {
				e.stopPropagation()
				setOpen(!open)
			}}>
				{dropdownElements ? <p className='navbar-link'>{text}</p> : <Link to={link}>{text}</Link>}
				{dropdownElements && <div className={`navbar-link-arrow ${open ? 'open' : ''}`}>{arrowIcon}</div>}
			</div>

			{
				dropdownElements && 
                <div className={`navbar-link-dropdown-container ${open ? 'open' : ''}`} 
					onClick={(e)=>{
						e.stopPropagation()
					}}
				>
                	<div className="navbar-link-dropdown-content-container">
                		{dropdownElements.map((elem) => {
                			return (
                				<div className={`navbar-link-dropdown-text-container ${elem.title == openedSection?.title ? 'open' : ''}`} onClick={(e)=>{
                					e.stopPropagation()
                					setOpenedSection(elem)
                				}}>
                					<div className="navbar-link-dropdown-icon-container">
                						{elem.icon}
                					</div>
                					<div className="navbar-link-dropdown-title-container">
										<TextUnderline color='var(--accentPrimary)'>
                							<Link className="navbar-link-dropdown-title" to={`${link}/${elem.title}${elem.link ? '/'+elem.link : ''}`} onClick={() => {setOpen(!open)}}>{t(elem.title)}</Link>
                						</TextUnderline>
										<p className="navbar-link-dropdown-subtitle">{t(`${elem.title}Subtitle`)}</p>
                					</div>
                				</div>
                			)
                		})}
                	</div>

                	<div className="navbar-link-dropdown-links-container">
                		<p className="navbar-link-dropdown-title">{t('browsOn')} {openedSection ? t(openedSection?.title) : null}</p>

                		{openedSection?.links.map((elem) => {
                			return (
								<TextUnderline color='var(--accentPrimary)'>
                					<Link to={elem.href} onClick={() => {setOpen(!open)}}>{t(elem.text)}</Link>
								</TextUnderline>
                			)
                		})}
                	</div>


                </div>
			}
		</div>
	)
}

export default NavbarLink