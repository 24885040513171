// styles
import './homeContent.scss'

// components
import LandingSlider from '../../general/landingSlider/landingSlider'
import TriplInfoContainer from '../../general/triplInfoContainer/triplInfoContainer'
import LandingTextSection from '../../general/landingTextSection/landingTextSection'
import SpecialOffer from '../../general/specialOffer/specialOffer'

//icons
import { bulb, employee, finance, project, sale, warehouse } from '../../../../../assets/general/generalIcons'
import { youtubeIcon } from '../../../assets/icons/icons'

const HomeContent = () => {

	return (
		<div className='home-content'>
			<LandingSlider
				translationWay='apps.landing.mainPage.sectionsDescription'
				stepsNumber={4}
				title='title'
				text='text'
				images={[
					require('../../../assets/images/appFeature/warehouses/Macbook1.png'),
					require('../../../assets/images/appFeature/finance/Macbook3.png'),
					require('../../../assets/images/appFeature/employees/Macbook3.png'),
					require('../../../assets/images/appFeature/sales/Macbook1.png'),
				]}
				webpImages={[
					require('../../../assets/images/appFeature/warehouses/Macbook1.webp'),
					require('../../../assets/images/appFeature/finance/Macbook3.webp'),
					require('../../../assets/images/appFeature/employees/Macbook3.webp'),
					require('../../../assets/images/appFeature/sales/Macbook1.webp'),
				]}
				smallText='smallText'
				icon={[
					sale,
					employee,
					finance,
					warehouse
				]}
			/>

			<LandingTextSection
				translation='apps.landing.mainPage.forWhom'
				text='text'
				title='title'
			/>	

			<TriplInfoContainer
				translation='apps.landing.mainPage.solutionToGlobalProblem'
				text='text'
				title='title'
				headerText='headerText'
				headerTitle='headerTitle'
				icon={bulb}
			/>

			<SpecialOffer
				translation='apps.landing.appInformation'
				text='specialOfferText'
				title='specialOfferTitle'

				button={{status: true, icon: youtubeIcon}}
			/>

			<LandingSlider
				translationWay='apps.landing.mainPage.integrationProcess'
				stepsNumber={5}
				title='title'
				text='text'
				headerTitle='mainTitle'
				headerText='mainText'
				images={[
					require('../../../assets/images/landing/Macbook1.png'),
					require('../../../assets/images/appFeature/projects/Macbook1.png'),
					require('../../../assets/images/appFeature/employees/Macbook1.png'),
					require('../../../assets/images/appFeature/sales/Macbook3.png'),
					require('../../../assets/images/appFeature/projects/Macbook3.png'),
				]}
				webpImages={[
					require('../../../assets/images/landing/Macbook1.webp'),
					require('../../../assets/images/appFeature/projects/Macbook1.webp'),
					require('../../../assets/images/appFeature/employees/Macbook1.webp'),
					require('../../../assets/images/appFeature/sales/Macbook3.webp'),
					require('../../../assets/images/appFeature/projects/Macbook3.webp'),
				]}
			/>	

			<LandingTextSection
				translation='apps.landing.mainPage.callToAction'
				text='text'
				title='title'
				icons={[
					{
						icon: project,
						iconPosition: {left: '3vw', top: '-13vw'}
					},
					{
						icon: finance,
						iconPosition: {left: '5vw', top: '31vw'}
					},
					{
						icon: warehouse,
						iconPosition: {left: '85vw', top: '30vw'}
					},
					{
						icon: employee,
						iconPosition: {left: '12vw', top: '12vw'}
					},
					{
						icon: sale,
						iconPosition: {left: '89vw', top: '-11vw'}
					},
					{
						icon: bulb,
						iconPosition: {left: '79vw', top: '12vw'}
					},
				]}
			/>		
		</div>
	)
}

export default HomeContent